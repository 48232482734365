import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "type-safety-at-runtime-in-typescript"
    }}>{`Type safety at runtime in TypeScript`}</h1>
    <h5 {...{
      "id": "april-29-2022--typescript-congress"
    }}>{`April 29, 2022 | `}<a parentName="h5" {...{
        "href": "https://typescriptcongress.com/"
      }}>{`TypeScript Congress`}</a></h5>
    <hr></hr>
    <ul>
      <li parentName="ul">{`📊 `}<strong parentName="li">{`Slides`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://slides.com/kajetansw/type-safety-at-runtime-caf588"
        }}>{`https://slides.com/kajetansw/type-safety-at-runtime-caf588`}</a></li>
      <li parentName="ul">{`⌚ `}<strong parentName="li">{`Duration`}</strong>{`: ~10 minutes`}</li>
      <li parentName="ul">{`👨‍💻 `}<strong parentName="li">{`Form`}</strong>{`: lightning talk`}</li>
    </ul>
    <hr></hr>
    <p>{`We all know, that TypeScript helps us in many ways. The compiler guides us during our work, ensuring, that every piece of data falls into a given place. `}</p>
    <p>{`But there are some limitations. TypeScript was meant to help us during development time. After the compilation step, we still cannot be 100% sure what can happen during runtime... Unless we do something about that and defend ourselves against unwanted runtime errors! `}</p>
    <p>{`This talk serves as an introduction to the problem and explains how we can face it to make our applications more error-proof.`}</p>
    <p>{`This is a v2 version of the `}<a parentName="p" {...{
        "href": "/speaking/type-safety-at-runtime/"
      }}>{`Type-safety at runtime with JSON decoders`}</a>{` talk I did.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      